import {
    DATING_EVENTBRITE_SUB_CATEGORY,
    EVENTBRITE_CATEGORY_MAPPING,
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { useEnvContext } from '../../../../context';
import {
    IconCategoryCard,
    IconCategoryWrapper,
} from '../../components/IconCategoryBrowse';
import { IconCategory } from '../../components/IconCategoryBrowse/types';
import { STATSIG_HOME_CLICK_TRENDING_CATEGORY } from '../../constants/analytics';
import {
    EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP,
    HALLOWEEN_CATEGORY_BROWSE_KEYS,
} from '../../constants/category';
import './IconCategoryBrowse.scss';

const getCategoryLink = (
    category: string,
    uses_language_subdirectory: boolean | undefined,
    locale: string | undefined,
) => {
    if (uses_language_subdirectory) {
        return category == DATING_EVENTBRITE_SUB_CATEGORY
            ? `/${locale}/b/local/${EVENTBRITE_CATEGORY_TO_REFINEMENT[HOME_LIFESTYLE_EVENTBRITE_CATEGORY]}/`
            : `/${locale}/b/local/`;
    }
    return category == DATING_EVENTBRITE_SUB_CATEGORY
        ? `/b/local/${EVENTBRITE_CATEGORY_TO_REFINEMENT[HOME_LIFESTYLE_EVENTBRITE_CATEGORY]}/`
        : `/b/local/`;
};

export const HalloweenIconCategoryBrowse = (props: { isMobile?: boolean }) => {
    const { env } = useEnvContext();
    const { uses_language_subdirectory, locale } = env?.localeInfo || {};
    const CATEGORIES: IconCategory[] = HALLOWEEN_CATEGORY_BROWSE_KEYS.map(
        (category) => {
            const categoryKey = EVENTBRITE_CATEGORY_TO_REFINEMENT[category];
            return {
                key: categoryKey,
                name: EVENTBRITE_CATEGORY_MAPPING[category],
                icon: EVENTBRITE_CATEGORY_CAROUSEL_ICON_MAP[category],
                link:
                    getCategoryLink(
                        category,
                        uses_language_subdirectory,
                        locale,
                    ) + categoryKey,
            };
        },
    );

    return (
        <section
            className="iconCategoryBrowse"
            data-testid="icon-category-browse"
        >
            <IconCategoryWrapper
                cards={CATEGORIES}
                noScrollMobile={props.isMobile}
            >
                {(category) => {
                    return (
                        <a
                            key={category?.key}
                            href={category?.link}
                            data-testid="category-card"
                            data-heap-id="homepage-category-tiles"
                            onClick={() => {
                                logEvent(STATSIG_HOME_CLICK_TRENDING_CATEGORY);
                            }}
                        >
                            <IconCategoryCard
                                title={category?.name || ''}
                                icon={category?.icon}
                            />
                        </a>
                    );
                }}
            </IconCategoryWrapper>
        </section>
    );
};
